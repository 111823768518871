import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";
import { trimEnd } from "lodash";

import useSiteMetadata from "../../hooks/useSiteMetadata";
import lang from '../../../i18n';
import Navigation from "./Navigation";
import GetStarted from "./GetStarted";
import Footer from "./Footer";

type Props = {
  children: React.ReactNode;
  location: Location;
}

const Layout = ({
  children,
  location,
}: Props) => {
  const { title, description, siteUrl } = useSiteMetadata();

  const [offset, setOffset] = useState(
    typeof window === "undefined" ? 0 : window.pageYOffset
  );

  const [isHome, setIsHome] = useState<boolean>(location.pathname === "/");

  useEffect(() => {
    if (location) {
      setIsHome(location.pathname === "/");
    }
  }, [location])

  const canonicalUrl = trimEnd(siteUrl + location.pathname, "/");

  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);


  const bodyClass = {
    class: offset > 40 ? "sticky" : "",
  };

  return (
    <div>
      <Helmet bodyAttributes={bodyClass}>
        <html lang={lang.code} />
        <title>{title}</title>
        <meta charSet="utf-8" />
        <link rel="canonical" href={canonicalUrl} />
        <meta http-equiv="x-ua-compatible" content="ie=edge" />
        <meta http-equiv="Expires" content="0" />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta name="robots" content="index, follow" />
        <meta
          name="googlebot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta
          name="bingbot"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content={lang.hrefLang} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link href="https://fonts.googleapis.com/css2?family=Epilogue:wght@600&family=Inter:wght@400;600&display=swap" rel="stylesheet" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/apple-touch-icon.png`}
        />
        <link
          rel="icon shortcut"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:url" content="/" />
        <meta property="og:image" content={`${withPrefix("/")}img/logo.png`} />
        <meta name="twitter:image" content={`${withPrefix("/")}img/logo.png`} />
      </Helmet>
      {/* <Navigation secondary={!isHome} /> */}
        <main>
          {children}
        </main>
      {/* <GetStarted withFaq={isHome} /> */}
      {/* <Footer /> */}
    </div>
  );
};

export default Layout;
