import React from "react";
import * as S from "./Divider.styled";

type Props = {
  color?: "white" | "gray5" | "black";
}

const Divider = ({ color = "white" }: Props) => (
  <S.DividerContainer>
    <S.Divider color={color} />
  </S.DividerContainer>
);

export default Divider;
