import styled from "styled-components";

export const DividerContainer = styled.div`
  height: 80px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: transparent;
`;

type DividerProps = {
  color: "white" | "gray5" | "black";
}

export const Divider = styled.div<DividerProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: 110%;
  transform: translateX(-50%) rotate(2deg);
  background-color: ${props => props.theme.colors[props.color]};
`;
